/*
    Theme Name: Spa
    Theme URI: https://themeies.com
    Author: Themeies
    Author URI: https://themeies.com
    Version: 1.0
    Description: Spa is a Landing HTML5 Template.
*/
/*
    Table of Context
    -------------------------------
    01.
*/
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
/* @import url("https://fonts.googleapis.com/css?family=Rufina:400,700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  color: #6c6c6f;
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 27px;
}

a {
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

figure {
  margin: 0;
}

section {
  display: block;
  position: relative;
  padding: 50px 0;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .nopadd-ltf {
    padding-left: 0;
  }
  .nopadd-rth {
    padding-right: 0;
  }
  .nopadd {
    padding-left: 0;
    padding-right: 0;
  }
}

/**
 *  Common CSS
 */
.bg-lightred {
  background-color: #fff9f8;
}

.btn {
  background-color: #debba9;
  border-radius: 0;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 0.8;
  text-transform: uppercase;
  min-width: 170px;
  padding: 20px 15px;
}

.btn-round {
  border-radius: 50px;
}

.btn-outline {
  background-color: transparent;
  border: 1px solid #fff;
}

.btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn.icon-left i {
  font-size: 20px;
  line-height: 0.8;
  margin-right: 10px;
}

.video-btn {
  color: #555;
  font-size: 15px;
  font-weight: 400;
}

.video-btn:hover {
  color: #debba9;
}

.video-btn i {
  border-radius: 50%;
  background-color: #fcf5ee;
  color: #debba9;
  display: inline-block;
  line-height: 44px;
  text-align: center;
  margin-right: 10px;
  height: 44px;
  width: 44px;
}

.sec-heading {
  margin-bottom: 32px;
  position: relative;
  text-align: center;
}

.sec-heading .foo-social a {
  color: #222222;
  display: inline-block;
  font-size: 34px;
  line-height: 0.8;
  padding: 0 10px;
}

.sec-heading .foo-social a:hover {
  color: rgb(0, 132, 255);
}

.sec-heading img {
  max-width: 84px;
  margin: 0 auto 40px;
}

.sec-heading .tagline {
  color: #888;
  display: block;
  font-size: 20px;
  font-weight: 400;
  line-height: 0.8;
  text-transform: uppercase;
  margin-bottom: 27px;
}

.sec-heading .sec-title {
  color: #1e2528;
  font-size: 42px;
  /* margin-bottom: 32px; */
}
.footer .foo-nap {
  width: 500px;
  flex-direction: row;
}

@media screen and (max-width: 767px) {
  .sec-heading .sec-title {
    font-size: 33px;
  }
}

.sec-heading p {
  color: #888;
  margin-bottom: 0;
}

.header {
  position: relative;
  padding: 15px 0;
}

.header.abs-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.logo {
  display: block;
  max-width: 109px;
}

.primary-menu,
.footer .foo-nav {
  position: relative;
}

.primary-menu .mobile-menu,
.footer .foo-nav .mobile-menu {
  display: none;
}

@media screen and (max-width: 767px) {
  .primary-menu .mobile-menu,
  .footer .foo-nav .mobile-menu {
    background: transparent;
    border: none;
    color: #debba9;
    display: block;
    font-size: 18px;
    position: absolute;
    right: 0;
    top: -30px;
    outline: 0;
  }
  .footer .foo-nap {
    width: 200px;
    flex-direction: column;
  }
}

@media screen and (min-width: 768px) {
  .primary-menu li,
  .footer .foo-nav li {
    display: inline-block;
  }
}

.primary-menu li a,
.footer .foo-nav li a {
  color: #263a41;
  display: block;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0 20px;
}

@media screen and (max-width: 991px) {
  .primary-menu li a {
    font-size: 13px;
    padding: 0 18px;
  }
}

.primary-menu li a:hover,
.footer .foo-nav li a:hover {
  color: #debba9;
}

@media screen and (max-width: 767px) {
  .primary-menu ul {
    background-color: #fcf5ee;
    /* display: none; */
    text-align: center;
    padding: 10px 0;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: 300ms;
    width: 100%;
    z-index: 9;
  }
  .primary-menu ul.active {
    /* display: block; */
    opacity: 1;
    visibility: visible;
  }
  .primary-menu li a {
    padding: 10px 15px;
  }
}

.banner {
  background: url(../images/leaf.png) no-repeat left center/12.5%;
  padding: 12% 0;
}

@media screen and (max-width: 1199px) {
  .banner {
    padding-top: 15%;
  }
}

@media screen and (max-width: 767px) {
  .banner {
    padding-top: 130px;
  }
}

.banner span {
  color: #debba9;
  display: block;
  font-family: "Nunito Sans", sans-serif;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.banner h2 {
  color: #1e2528;
  font-size: 60px;
  line-height: 75px;
  margin-bottom: 25px;
}

@media screen and (max-width: 1199px) {
  .banner h2 {
    font-size: 49px;
    line-height: 60px;
  }
}

@media screen and (max-width: 479px) {
  .banner h2 {
    font-size: 44px;
    line-height: 54px;
  }
}

.banner p {
  color: #555;
  margin-bottom: 50px;
}

@media screen and (min-width: 480px) {
  .banner .video-btn {
    margin-left: 15px;
  }
}

@media screen and (max-width: 479px) {
  .banner .video-btn {
    display: block;
    margin-top: 30px;
  }
}

.banner .spa-img {
  width: 43.4%;
  position: absolute;
  right: 0;
  top: 0;
}

@media screen and (max-width: 991px) {
  .banner {
    text-align: center;
    margin-top: 50px;
  }
  .banner .spa-img {
    display: none;
  }
}

.about {
  text-align: center;
}

.about [class|="flower"] {
  position: absolute;
}

.about .flower-1 {
  left: 16%;
  top: 0;
  max-width: 160px;
}

.about .flower-2 {
  right: 135px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  max-width: 88px;
}

@media screen and (max-width: 991px) {
  .about .flower-2 {
    display: none;
  }
}

.service .service-caro {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.service .single-service {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  position: relative;
}

.service .single-service .service-hover {
  /* background-color: rgba(255, 129, 126, 0.9); */
  box-shadow: inset 0px 0px 50px 10px rgba(255, 129, 126, 0.9);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  opacity: 0;
  visibility: hidden;
  height: 100%;
  width: 100%;
}

.service .single-service .service-hover img {
  margin: 0 auto 20px;
  width: 47px;
}

.service .single-service .service-hover span {
  color: #fff;
  display: block;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
}

.service .single-service:hover .service-hover {
  opacity: 1;
  visibility: visible;
}

.procedures {
  position: relative;
}

.blog {
  padding-bottom: 20px;
}

.post {
  border: 1px solid #e3e6ea;
  text-align: center;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  padding: 20px;
  margin-bottom: 30px;
}

.post img {
  margin-bottom: 35px;
}

.post h4 {
  margin-bottom: 22px;
}

.post h4,
.post h4 a {
  color: #263a41;
  font-size: 22px;
}

.post p {
  margin-bottom: 5px;
}

.post .btn {
  background-color: #30383b;
  margin-bottom: 30px;
  padding: 15px;
}

.post:hover {
  -webkit-box-shadow: 0 5px 20px #f0f0f0;
  box-shadow: 0 5px 20px #f0f0f0;
}

.post:hover .btn {
  background-color: #debba9;
  color: #fff;
}

.testimonial .single-tst {
  position: relative;
  text-align: center;
  margin-bottom: 30px;
}

.testimonial .single-tst img {
  margin: 0 auto 45px;
  width: 59px;
}

.testimonial .single-tst p {
  color: #263a41;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  margin-bottom: 43px;
}

.testimonial .single-tst .client-info .thumb {
  border-radius: 50%;
  margin-bottom: 22px;
  height: 76px;
  width: 76px;
}

.testimonial .single-tst .client-info p {
  font-size: 22px;
  margin-bottom: 0;
}

.testimonial .single-tst .client-info p span {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 300;
}

.testimonial .owl-dots {
  text-align: center;
}

.testimonial .owl-dots span {
  background-color: #ffc3c2;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
  height: 8px;
  width: 8px;
}

.appointment .v2 {
  padding-bottom: 0;
  border-bottom: 1px solid #fdd5d5;
}

.appointment .appointment-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.appointment .appointment-wrap figure {
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
}

@media screen and (max-width: 1300px) {
  .appointment .appointment-wrap figure {
    display: none;
  }
}

.appointment .appointment-wrap .appointment-form {
  -ms-flex-preferred-size: 60%;
  flex-basis: 60%;
  padding-left: 100px;
}

@media screen and (max-width: 1300px) {
  .appointment .appointment-wrap .appointment-form {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    padding: 30px 15px;
  }
}

.appointment .appointment-wrap .appointment-form form {
  max-width: 650px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media screen and (max-width: 1300px) {
  .appointment .appointment-wrap .appointment-form form {
    margin: 0 auto;
  }
}

.appointment-form {
  background-color: #fff9f8;
}

.appointment-form .form-field {
  position: relative;
  width: 100%;
}

.appointment-form .form-field input,
.appointment-form .form-field textarea,
.appointment-form .form-field select {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #d4d3d3;
  color: #999;
  display: block;
  font-size: 12px;
  font-weight: 400;
  padding: 10px 0;
  margin-bottom: 30px;
  width: 100%;
}

.appointment-form .form-field input::-webkit-input-placeholder,
.appointment-form .form-field textarea::-webkit-input-placeholder,
.appointment-form .form-field select::-webkit-input-placeholder {
  opacity: 1;
  text-transform: uppercase;
}

.appointment-form .form-field input:-ms-input-placeholder,
.appointment-form .form-field textarea:-ms-input-placeholder,
.appointment-form .form-field select:-ms-input-placeholder {
  opacity: 1;
  text-transform: uppercase;
}

.appointment-form .form-field input::-ms-input-placeholder,
.appointment-form .form-field textarea::-ms-input-placeholder,
.appointment-form .form-field select::-ms-input-placeholder {
  opacity: 1;
  text-transform: uppercase;
}

.appointment-form .form-field input::placeholder,
.appointment-form .form-field textarea::placeholder,
.appointment-form .form-field select::placeholder {
  opacity: 1;
  text-transform: uppercase;
}

.appointment-form .form-field select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-transform: uppercase;
}

.appointment-form .form-field .select-field {
  width: 100%;
}

@media screen and (min-width: 480px) {
  .appointment-form .form-field.half-width {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .appointment-form .form-field.half-width input,
  .appointment-form .form-field.half-width .select-field {
    width: 49%;
  }
}

.team .single-memb {
  position: relative;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .team .single-memb {
    margin-bottom: 30px;
  }
}

.team .single-memb .memb-details {
  background-color: #fff;
  -webkit-box-shadow: 0 5px 20px #f0f0f0;
  box-shadow: 0 5px 20px #f0f0f0;
  max-width: calc(100% - 60px);
  margin: -50px auto 0;
  padding: 30px;
  position: relative;
  text-align: center;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  z-index: 1;
}

@media screen and (max-width: 1199px) {
  .team .single-memb .memb-details {
    padding: 30px 20px;
  }
}

@media screen and (max-width: 991px) {
  .team .single-memb .memb-details {
    max-width: 100%;
    padding: 20px 15px;
  }
}

.team .single-memb .memb-details h6 {
  color: #263a41;
  font-size: 22px;
  margin-bottom: 0;
}

.team .single-memb .memb-details span {
  display: block;
  margin-bottom: 15px;
}

.team .single-memb .memb-social {
  margin-top: -30px;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.team .single-memb .memb-social a {
  border: 1px solid #30383b;
  border-radius: 50%;
  color: #30383b;
  display: inline-block;
  font-size: 13px;
  text-align: center;
  line-height: 30px;
  margin: 0 5px;
  height: 30px;
  width: 30px;
}

.team .single-memb .memb-social a:hover {
  background-color: #debba9;
  border-color: #debba9;
  color: #fff;
}

.team .single-memb:hover .memb-details {
  margin-top: -84px;
}

.team .single-memb:hover .memb-social {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}

.contact address {
  color: #888;
  font-size: 14px;
  font-weight: 400;
  padding-left: 80px;
  position: relative;
}

.contact address a {
  color: #888;
  display: block;
  font-size: 14px;
  font-weight: 400;
  transform: translate(0, 11px);
}

.contact address a:hover {
  color: #debba9;
}

.contact address span {
  background-color: #debba9;
  border-radius: 50%;
  color: #fff;
  display: block;
  font-size: 14px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 50px;
  width: 50px;
}

.contact .contact-form {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .contact .contact-form {
    margin-top: 40px;
  }
}

.contact .contact-form input,
.contact .contact-form textarea {
  border: 1px solid #e3e6ea;
  display: block;
  padding: 10px 20px;
  margin-bottom: 25px;
  width: 100%;
}

.contact .contact-form textarea {
  resize: none;
}

@media screen and (max-width: 767px) {
  .map {
    padding-top: 0;
  }
}

#map {
  position: relative;
  height: 500px;
  width: 100%;
}

@media screen and (max-width: 991px) {
  #map {
    height: 350px;
  }
}

@media screen and (max-width: 767px) {
  #map {
    height: 250px;
  }
}

.blog-details-sec .post-featImg {
  margin-bottom: 50px;
}

.blog-details-sec .blog-single-post blockquote {
  background-color: #fff9f8;
  padding: 37px 50px;
  margin: 30px 0 40px;
}

.comment-title {
  border-bottom: 2px solid #1e2528;
  color: #1e2528;
  display: inline-block;
  font-size: 18px;
  text-transform: capitalize;
  position: relative;
  padding-bottom: 0;
  margin-bottom: 30px;
}

.comments {
  position: relative;
  margin-bottom: 50px;
}

.comments .reply {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 0;
  right: 0;
  color: #e57d80;
  letter-spacing: 2.8px;
}

.comments ul {
  list-style: none;
}

.comments ul li {
  position: relative;
  margin-bottom: 34px;
  padding-left: 40px;
}

@media screen and (max-width: 479px) {
  .comments ul li {
    padding-left: 30px;
  }
}

.comments ul li h4 {
  color: #1e2528;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
}

.comments ul li p {
  font-size: 13px;
  line-height: 24px;
  margin-bottom: 10px;
}

.comments ul li a,
.comments ul li span {
  color: #6c6c6f;
  display: inline-block;
  font-size: 13px;
  font-weight: 300;
  padding: 0 15px;
}

@media screen and (max-width: 479px) {
  .comments ul li a,
  .comments ul li span {
    padding: 0 5px;
  }
}

.comments ul li a:hover {
  color: #debba9;
}

.comments ul li a:first-of-type {
  padding-left: 0;
}

.comments ul li span i {
  color: #1e2528;
  font-size: 13px;
  margin-right: 3px;
  vertical-align: middle;
}

.comments ul li ul li:first-child {
  margin-top: 35px;
}

.comments .single-comment {
  border: 1px solid #e3e6ea;
  border-radius: 5px;
  padding: 25px 60px;
  position: relative;
}

@media screen and (max-width: 575px) {
  .comments .single-comment {
    padding-right: 15px;
  }
}

@media screen and (max-width: 479px) {
  .comments .single-comment {
    padding-left: 40px;
  }
}

.comments .user-thumb {
  border-radius: 50%;
  position: absolute;
  left: -40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 80px;
  overflow: hidden;
  width: 80px;
}

@media screen and (max-width: 479px) {
  .comments .user-thumb {
    height: 65px;
    width: 65px;
  }
}

@media screen and (max-width: 767px) {
  .comment-form {
    margin-bottom: 40px;
  }
}

.comment-form form {
  width: 100%;
  display: block;
  position: relative;
}

.comment-form form .input-field {
  display: block;
  margin-bottom: 25px;
  width: 100%;
}

.comment-form form .input-field label {
  color: #1e2528;
  display: block;
  font-size: 13px;
  margin-bottom: 10px;
}

.comment-form input,
.comment-form textarea {
  background: transparent;
  border-radius: 3px;
  border: 1px solid #e3e6ea;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 7px 20px;
  width: 100%;
  margin-bottom: 30px;
}

.comment-form textarea {
  height: 200px;
}

.comment-form button {
  background-color: #debba9;
  text-transform: uppercase;
  padding: 15px 30px;
}

.footer {
  background-color: #fff9f8;
  padding: 40px 0 60px;
}
.footer p span.address:hover {
  color: blue;
}

.footer .foo-logo {
  margin: 0 auto;
}

.footer .foo-nav {
  margin: 30px 0;
}

.footer .foo-social {
  margin-bottom: 30px;
}

.footer .foo-social a {
  /* border-right: 1px solid #b1b4bb; */
  color: #3a414e;
  display: inline-block;
  font-size: 14px;
  line-height: 0.8;
  padding: 0 10px;
}

.footer .foo-social a:first-child {
  border-right: 1px solid #b1b4bb;
}

.footer .foo-social a:hover {
  color: #debba9;
}

.footer .copyright {
  color: #888;
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.footer .copyright a {
  color: #debba9;
}

/* Sidebar */
.sidebar .widget {
  border: 1px solid #e0e4e6;
  border-radius: 5px;
  padding: 25px;
  position: relative;
  margin-bottom: 30px;
}

.sidebar .widget .widget-title {
  text-transform: capitalize;
  margin-bottom: 20px;
}
.categories {
  padding-bottom: 40px;
}

.categories li a {
  color: #83868c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #e0e4e6;
  padding: 7px 0;
}

.categories li a span {
  display: inline-block;
  margin-left: auto;
}

.categories li:last-child a {
  border-bottom: 0;
}
.widget_search {
  padding: 0 !important;
  border: none !important;
}

.widget_search form {
  position: relative;
}

.widget_search form i {
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.widget_search input {
  background-color: none;
  border: 1px solid #e0e4e6;
  border-radius: 5px;
  padding: 15px 40px 15px 25px;
  width: 100%;
}

.entry-content,
.entry-content-fluid {
  position: relative;
  margin-bottom: 60px;
}

.entry-content h3,
.entry-content-fluid h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
  /* text-transform: capitalize; */
}

.entry-content h6,
.entry-content-fluid h6 {
  margin: 40px 0 20px;
}

.entry-content .meta-tags,
.entry-content-fluid .meta-tags {
  border-bottom: 1px solid #e0e4e6;
  margin: 30px 0;
  padding-bottom: 20px;
}

.entry-content .meta-tags a,
.entry-content-fluid .meta-tags a {
  color: #83868c;
  font-size: 12px;
  text-transform: capitalize;
  margin-right: 10px;
  padding-right: 10px;
  position: relative;
}

.entry-content .meta-tags a i,
.entry-content-fluid .meta-tags a i {
  margin-right: 10px;
}

.entry-content .meta-tags a:not(:last-child):after,
.entry-content-fluid .meta-tags a:not(:last-child):after {
  content: "";
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  bottom: 0;
  background-color: #83868c;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 3px;
  width: 3px;
}

@media screen and (min-width: 768px) {
  .entry-content-fluid {
    max-width: 540px;
    width: 100%;
  }
}

.tag-cloud {
  display: flex;
  flex-wrap: wrap;
}

.tag-cloud a {
  border-radius: 5px;
  color: #6c6c6f;
  text-align: left;
  padding: 0 7px;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.tag-cloud a i {
  transform: rotate(45deg);
  display: inline-block;
}

/*************************
 * Page Feature
 */
.page-feature {
  background: url(../images/page-feat.jpg) no-repeat top center/cover;
  padding: 100px 0;
  position: relative;
}

.page-feature::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  height: 100%;
  width: 100%;
}

.page-feature h2 {
  color: #fff;
  text-transform: capitalize;
  position: relative;
  z-index: 3;
}

.page-feature .breadcrumb {
  background-color: transparent;
  color: #fff;
  display: block;
  text-transform: capitalize;
  position: relative;
  margin-bottom: 0;
  z-index: 3;
}

.page-feature .breadcrumb a {
  color: #ccc;
}

.error {
  position: relative;
  display: block;
  width: 100%;
}

.error strong {
  font-size: 400px;
  display: block;
  line-height: 400px;
  color: #fff;
  text-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

@media screen and (max-width: 991px) {
  .error strong {
    font-size: 300px;
    line-height: 300px;
  }
}

@media screen and (max-width: 575px) {
  .error strong {
    font-size: 200px;
    line-height: 200px;
  }
}

@media screen and (max-width: 479px) {
  .error strong {
    font-size: 160px;
    line-height: 160px;
  }
  .error h2 {
    font-size: 18px;
  }
  .error .btn {
    font-size: 14px;
  }
}

.error h2 {
  margin-bottom: 30px;
}

/* Custom CSS */
.preloader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.preloader img {
  width: auto;
  margin: 0 auto;
}
.appointment-form .form-field input,
.appointment-form .form-field select,
.appointment-form .form-field textarea {
  color: #111;
  font-size: 14px;
}
.appointment-form textarea:focus,
.appointment-form select:focus,
.appointment-form input:focus {
  outline: 0;
}

.everyEven:nth-child(odd) {
  background-color: rgba(222, 187, 169, 0.2);
}
